import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["image", "form"]

  connect () {

    if (this.imageTarget) {
      this.adjustLayout();
    }
  }

  adjustLayout () {
    const height = this.imageTarget.clientHeight
    this.formTarget.style.marginBottom = (height * 0.3) + 'px';
  }
}
