import { Controller } from "@hotwired/stimulus"
import { Chart, registerables } from "https://cdn.skypack.dev/chart.js@4.4.6?min"

Chart.register(...registerables)
Chart.defaults.color = "#484848"
Chart.defaults.font.size = 14
Chart.defaults.borderColor = "#BCBCBC"

export default class extends Controller {
  static values = {
    data: Object,
    labelsWeeks: Array,
    labelsTotalCashflow: Array,
    currencySymbol: String,
    selectedWeek: Object
  }

  connect () {
    this.chart = new Chart(this.element, this.#settings)
  }

  disconnect () {
    this.chart.destroy()
  }

  selectedWeekValueChanged (value, previousValue) {
    if (this.chart) {
      this.chart.update();
      this.updateWidgetsSrc()
    }
  }

  updateWidgetsSrc () {
    const inflowsFrame = document.querySelector('turbo-frame#cashflow_summaries_inflows_widget')
    const outflowsFrame = document.querySelector('turbo-frame#cashflow_summaries_outflows_widget')

    const additionalParams = { selected_week_id: this.selectedWeekValue?.id };

    [inflowsFrame, outflowsFrame].forEach(frame => this.updateFrameSrc(frame, additionalParams));
  }

  updateFrameSrc = (frame, params) => {
    if (!frame) return;

    const url = new URL(frame.src, window.location.origin);

    Object.entries(params).forEach(([key, value]) => {
      if (value == null) {
        url.searchParams.delete(key);
      } else {
        url.searchParams.set(key, value);
      }
    });

    frame.src = url.toString();
  }

  get #settings () {
    return {
      data: this.dataValue,
      options: {
        onHover: (event, chartElements) => {
          event.native.target.style.cursor = chartElements[0] ? 'pointer' : 'default';
        },

        // The main click handler
        onClick: (event, activeElements) => {
          if (activeElements.length > 0) {
            // User clicked on a bar

            const { index } = activeElements[0]
            const clickedWeek = this.labelsWeeksValue[index]
            console.log(index)
            // Toggle selection if the same ID is clicked again
            if (this.selectedWeekValue.id === clickedWeek.id) {
              this.selectedWeekValue = {}
            } else {
              this.selectedWeekValue = clickedWeek
            }
          } else {
            // Clicked outside any bar => unselect
            this.selectedWeekValue = {}
          }
        },
        scales: {
          x: {
            labels: this.labelsTotalCashflowValue,
            ticks: {
              callback: (value) => {
                const labelValue = parseFloat(this.labelsTotalCashflowValue[value])
                return this.formatNumber(labelValue);
              },
              font: {
                weight: '500',
                size: 15,
              },
              color: (context) => {
                const labelValue = parseFloat(context.tick.label)
                if (labelValue === 0) return '#484848';

                return labelValue > 0 ? '#51C4BC' : '#E40000';
              },
            },
            grid: {
              display: false,
            },
          },
          x2: {
            type: "category",
            position: "bottom",
            offset: true,
            labels: this.labelsWeeksValue.map(week => week.week_name),
            ticks: {
              font: (context) => {
                if (this.selectedWeekValue.id) {
                  return this.selectedWeekValue.week_name === context.tick.label ? {
                    weight: '900',
                    size: 15
                  } : { weight: '500', size: 15 }
                } else {
                  return { weight: '500', size: 15 }
                }
              },
              color: (context) => {
                if (this.selectedWeekValue.id) {
                  return this.selectedWeekValue.week_name === context.tick.label ? "#000000" : "#AAAAAA"
                } else {
                  return "#000000"
                }
              },
            },
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
          },
          inflowsAndOutflowsY: {
            title: {
              display: true,
              text: "Inflows/Outflows",
              font: {
                size: 18,
                lineHeight: 2,
              }
            },
            border: {
              display: false,
            },
            grid: {
              lineWidth: 0.63,
            },
            position: 'left',
            min: 0,
            grace: '10%',
            ticks: {
              callback: (value) => this.formatNumber(value)
            }
          },
          l1: {
            title: {
              display: true,
              text: "Cumulative cashflow",
              font: {
                size: 18,
                lineHeight: 2,
              }
            },
            border: {
              display: false,
            },
            beginAtZero: false,
            position: 'right',
            grace: '10%',
            grid: {
              display: false,
              drawOnChartArea: false,
            },
            ticks: {
              callback: (value) => this.formatNumber(value)
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: (tooltipItem) => {
                const index = tooltipItem[0].dataIndex;
                const weekData = this.labelsWeeksValue[index];
                return `${weekData.week_name} | ${weekData.description}` || '';
              },
            }
          }
        }
      }
    }
  }

  formatNumber (number) {
    const inThousands = number / 1000;
    return inThousands.toFixed(0) + 'k' + this.currencySymbolValue;
  }
}
